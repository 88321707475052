<template>
    <v-custom-autocomplete
        class="size_large"
        :icon="icon"
        :placeholder="placeholder"
        :label="label"
        v-model="search"
        :list="showList?driversList||[]:[]"
        :notes="searchLimit"
        :buttonIcon="buttonIcon"
        :buttonIconClass="{'await':true}"
        :isload="isSearchLoadding"
        @input="HandleInput"
        @update="HandlerUpdate($event)"
        :stopSorting="true"
    />
</template>

<script>
import api from '../../../api'
import vCustomAutocomplete from '../../global/CustomAutocomplete'
export default {
    name:'DrivevrSearch',
    props: {
        icon:{
            type: String,
            defalt:''
        },
        placeholder:{
            type: String,
            defalt:''
        }, 
        label:{
            type: String,
            defalt:''
        }, 
        buttonIcon: {
            type: String,
            default: ''
        },
        minLength:{
            type: Number,
            default:5
        },
        showList: {
            type: Boolean,
            default:true
        }
    },
    components: {
        vCustomAutocomplete
    },
    computed: {
        searchLimit() {
            const limit = this.minLength - this.search.text.length
            if(limit > 0) {
                return 'еще '+limit+' символов'
            }
            return null
        },
    },
    data() {
        return {
            isSearchLoadding:false,

            search:{id:null , text:''},
            timer: false,
            driversList: false,
            time: false
        }
    },
    methods: {
        HandlerUpdate(item) {
            this.$emit('select',item)
        },
        HandleInput() {
            let _this = this

            if(this.timer) clearTimeout(this.timer)
            const time = new Date().getTime()
            this.time = time
            this.timer = setTimeout(function() {
                if(!_this.search.text.length || (_this.search.text.length < _this.minLength)){
                    return 
                }
                _this.apiRequest(time)
            }, 500, this);

        },
        apiRequest(time) {
            this.isSearchLoadding = true
            const query = {criteria: this.search.text, retrieve: true}
            // запрос на сервак
            api.drivers.search(query).then(res=>{
                if(this.time && time != this.time) return
                this.driversList = res.response.data
                if(!this.driversList.length && this.search.text) {
                    // если результатов нету
                    this.driversList = [{
                        id: false,
                        text: 'Нет результатов',
                        selectable: false
                    }]
                } else { 
                    // добовляем текст
                    this.driversList.forEach(element => {
                        const {full_name,park_name} = element
                        element.text = full_name.text +' - '+ park_name
                    })
                }
                this.$emit('listupdate',this.driversList)
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
                console.error({err});
            }).finally(()=>{
                
                if(this.time && time != this.time) return
                this.isSearchLoadding = false
            })
        },
    },
}
</script>