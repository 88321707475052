import Janus from './janus/vJanus'
import helpers from './janus/helpers'
import api from '@api'
import { eventBus } from '@eventBus'
import Timer from './timer'
import { mapState } from 'vuex'

export default {
    mixins: [Janus,helpers],
    
  computed: {
    ...mapState({
        user: state => state.profile.user,
    }),
},
    data() {
        return {
            interval: null,
            callTimer: null,
            mid: null,
            telProp: {
                JanysReady: false,
                calling: false,
                isCall: false,
                callList: [],
                currentCall: false,
                telInput: {
                    plugin: false,
                    phoneNumber: '',
                    timer: ''
                }
            },
            agentName: null,
            audioList: [],
            contacts: [],
            janus: null,
            masterId: null,
            currentIncallJanus: null,
            settings: null,
            isMainPluginBizzy: false,
            isNoising: false,
            waitChange: false,
            tempStatistic: null

        }
    },
    mounted() {
        
        const _this = this
        document.addEventListener("visibilitychange", function() {
            if (document.hidden) {
               // _this.startBlinkingTitle();
            } else {
                _this.stopBlinkingTitle();
            } 
        });
        
        document.addEventListener('keydown', this.handleKeyPress);
        eventBus.$on('companyIsChanget', this.sendTempStatistic)
        eventBus.$on('createCall', this.createCall)
    },
    methods: {
        sendTempStatistic() {
            this.waitChange = false
            if(this.tempStatistic) {
                this.statisticSend(this.tempStatistic)
                this.tempStatistic = null
            }
        },
        timerUpdate(timer) {
            this.telProp.telInput.timer = timer.textContent
        },
        handleKeyPress(event) {
            if (event.metaKey && event.altKey) {
                const current = this.telProp.currentCall
                if(current) {
                    this.vJanusHangupCall(current)
                } else {
                    const firstIncommingCall = this.getFirstIncCall()
                    if(!firstIncommingCall) return
                    event.defaultPrevented
                    this.vJanusAcceptIncoming(firstIncommingCall)
                }
            }
        },
        getSettings() {
            return api.profile.info().then(res=>{
                return res.response.telephony_info
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        vJanInit(){
            this.getSettings().then(settings=>{
                if(this.janus) {
                    console.error('vJanInit: janus уже есть ', settings)
                    return
                }
                this.settings = settings
                const {id, ip, password, port, wss_url} = this.settings
                if(id && ip && password && port && wss_url) {
                    this.janusinit()
                } else {
                    console.error('vJanInit: не все данные', {id, ip, password, port, wss_url})
                }
            })
        },
        getFirstIncCall() {
            return this.telProp.callList.find((item)=>{
                if(item.plugin) return item.plugin
            })
        },
        

        setTransferContact(data) {
            this.telProp.callList.some((item)=>{
                if(item.contact && item.contact.id ) {
                    const id = item.contact.id.split('@')[0]
                    if(id == data.call_id) item.contact.transferring = true
                }
            })
        },
        async vjanMessage(plugin, obj) {
            const {jsep,msg} = obj
            const callId = msg["call_id"];
            const result = msg["result"] || {};
            const message = result["event"] || '';
            let referredBy = result["referred_by"];
            if(result["event"] == 'message' || result["event"] =='info' ) {
                const sender = result["displayname"] ? result["displayname"] : result["sender"];
                let content = result["content"];
                content = content.replace(new RegExp('<', 'g'), '&lt');
                content = content.replace(new RegExp('>', 'g'), '&gt');
            }

            const data  = {
                event: message,
                msg,
            }

            if (message == 'calling')  {
                data.phoneNumber =this.telProp.telInput.phoneNumber
            }

            if(!this.waitChange) {
                this.statisticSend(data)
                this.tempStatistic = null
            } else {
                this.tempStatistic = data
                this.tempStatistic.prev_user = this.user.id
                this.waitChange = false
            }
            

            if (result && result["event"]) {
                switch (result["event"]) {
                    case "registering":
                    break
                    case "registered":
                        if(!result.helper) this.$toast(message)
                        this.telProp.JanysReady = true
                        this.vJanusReady(msg.result)
                        this.masterId = result["master_id"];
                        break
                    case "registration_failed":
                        //this.$toast.error(message)
                        this.vjanusRegisterFaild({msg, jsep})
                        break
                    case "calling":
                        this.telProp.calling = true
                        //this.$toast(message)
                        break
                    case "incomingcall":
                        //this.$toast(message)
                        const contact = await this.createContact(msg)
                        this.contacts.push(msg)
                        this.vjanusIncomingCall(plugin, contact, jsep)

                        break
                    case 'accepting':
                        break
                    case 'progress': 
                        if (jsep) {
                            plugin.handleRemoteJsep({
                                jsep: jsep,
                                error: (error) => {
                                    console.error('progress handleRemoteJsep', error)
                                }});
                            }
                        break
                    case 'accepted':
                        const _plugin = this.plugin 
                        if (jsep) {
                            plugin.handleRemoteJsep({
                                jsep: jsep, 
                                error: (error) => {
                                    console.error('handleRemoteJsep', error)
                                }
                            });
                        }
                        this.plugin.callId = callId
                        this.vjanusAccepted(plugin, msg)
                        break
                    case 'updatingcall': 
                        const _this = this
                        this.plugin.createAnswer({
                            jsep: jsep, 
                            tracks: [{type: 'audio', capture: true, recv: true}], 
                            success: function (jsep) {
                                let body = {request: "update"};
                                _this.plugin.send({message: body, jsep: jsep});
                            }, error: function (error) {
                                console.error(error)
                            }
                        });
                        break
                    case 'message':
                        break
                    case 'info':
                    case 'notify': 
                        break
                    case 'transferring':
                        break
                    case 'hangup':
                        this.sockethangup(plugin,msg)
                        break
                }
            }

        },
        vjanusRegisterFaild(obj) {
            const result = obj.msg.result
            const message = result.event+':'+result.reason
            // this.$toast.error(message)
        },
        sockethangup(plugin,msg) {
            const {result} = msg

            plugin.hangup()
            const contact = this.getContact(msg);
            const currentCall = this.telProp.currentCall
            if(currentCall && currentCall.plugin){
                const currentPluginId = currentCall.plugin && (currentCall.plugin.id || currentCall.plugin.getId())
                if(currentPluginId == plugin.getId()) {
                    this.hangupTelInputCall()
                }
            }
            const isInList = this.getIdFromListPlugin({plugin})
            if(isInList >-1) {
                const item = this.telProp.callList[isInList]
                item.plugin = null
                item.timer = setTimeout(() => {
                    this.removeFromCallList(contact)
                    //this.vjanusHangup({contact})
                }, 5000);
            }

            this.clearTimer()
        },
        vJanusReady(output) {
            this.agentName = output.username
        },

        vJanusTabCall(data) {
            this.vJanusMakeCall(data.phoneNumber)
            this.telProp.telInput.dump_id = data.dump_id
            // if(data.dump_id) this.createCall(data)
        },
        

        createCall(data) {
            const phoneNumber = this.telProp.telInput.phoneNumber
            if(this.telProp.telInput.dump_id && phoneNumber && data.phone == phoneNumber) {
                this.assetCall({
                    dump_id: this.telProp.telInput.dump_id,
                    call_id: data.uuid
                })
                this.telProp.telInput.dump_id = null
            } else {
                if(!data) return
                const index = this.getItemIndexFromCallList({id:data.call_id})
                const item = this.telProp.callList[index]
                if(!item) return
                item.contact.transferring = data.isTransfer
                item.contact.uuid = data.uuid
            }
        },

        

        // функция создания вызова
        vJanusMakeCall(phoneNumber) { 
            if(this.telProp.calling) return 
            let plugin = this.plugin
            if(this.isMainPluginBizzy ) {
                const lastHelper = this.getLastHelper() 
                plugin = lastHelper.sipcall
            } 
            
            this.currentIncallJanus = plugin
            this.telProp.calling = true

            const tracks = [{type: 'audio', capture: true, recv: true}]
            const {ip} = this.settings
            const uri = `sip:${phoneNumber.match(/\d+/g)}@${ip}`
            const _this = this
            
            plugin.createOffer({
                tracks, 
                success: function (jsep) {
                    let body = {request: "call", uri};
                    body["autoaccept_reinvites"] = false;
                    plugin.send({message: body, jsep: jsep});
                },
                error: function (error) {
                    console.error('createOffer',error)
                }
            });

            this.telProp.telInput.plugin = plugin

            this.addHelper()
        },

        // функция принятьия вызова
        vJanusAcceptIncoming(data) {
            this.telProp.calling = true
            const plugin = data.plugin
            let sipcallAction = plugin.createOffer
            if(data.jsep) sipcallAction = plugin.createAnswer
            this.currentIncallJanus = plugin

            let tracks = [{
                type: 'audio',
                capture: true,
                recv: true
            }]
            let jsep = data.jsep
            

            const company = data.contact.company || false           
            eventBus.$emit('vJanusAcceptIncoming', data.contact);
            if(company && (company.number || company.company_name)) {
                if(company.can_change_company) this.waitChange = true
            }

            sipcallAction({
                jsep: jsep, 
                tracks: tracks, 
                success: (jsep) => {
                    plugin.doAudio = true;
                    let body = {request: "accept"};
                    body["autoaccept_reinvites"] = false;
                    // Успешно создан SDP, теперь отправьте его в запросе на принятие вызова.
                    plugin.send({
                        message: body, 
                        jsep: jsep, 
                        success: (response) => {
                            
                            // this.telProp.currentCall = data
                            // const index = this.getItemIndexFromCallList(data.contact.number)
                            // this.telProp.callList.splice(index, 1)
                        },
                        error: (err) =>{
                            console.error(err);
                        }
                    });
                },
                error: (error) => {
                    this.telProp.calling = false
                    this.currentIncallJanus = null,
                    this.waitChange = false

                    console.error('Ошибка при создании SDP:', error);

                    let body = {request: "decline", code: 480};
                    plugin.send({message: body});
                }
            });
        }, 
        vjanusHangup(data){
            const index = this.getItemIndexFromCallList(data.contact)
            this.removeFromCallList(data.contact)
        },

        // сброс вызова
        vJanusHangupCall(item) {            
            const _this = this
            const plugin = item && item.plugin? item.plugin :  this.currentIncallJanus
            const telInputId = this.telProp.telInput.plugin && (this.telProp.telInput.plugin.id || this.telProp.telInput.plugin.getId())
            
            plugin.send({
                message: { request: "hangup"},
                success: () => {
                    if(telInputId == plugin.getId()) {
                        _this.hangupTelInputCall()
                    }
                    _this.removeFromCallList(item.contact)
                },
                error: (error) => {
                    console.error('Ошибка при завершении вызова:', error);
                }
            });
            this.clearTimer()
        },
        removeFromCallList(contact) {
            const index = this.getItemIndexFromCallList(contact)
            if(index > -1) this.telProp.callList.splice(index, 1)
            if(this.telProp.currentCall && this.telProp.currentCall.contact.id == contact.id) {
                this.hangupTelInputCall()
            }
            this.clearTimer()
        }, 

        // перевод звонка
        vJanusTransferCall(item) {
            const {ip,port} = this.settings
            const uri =  'sip:'+item.number+'@'+ip+':'+port
            const plugin = this.currentIncallJanus
            const msg = {
                request: "transfer",
                uri,
            }
            this.telProp.currentCall.contact.transferring = item.number
            plugin.send({message: msg});
        },
        vJanusMuteAudio() {
            const plugin = this.currentIncallJanus
            const isMuted = plugin.isAudioMuted()
            if(isMuted) {
                plugin.unmuteAudio(false)
            } else {
                plugin.muteAudio(false)
            }
            this.$nextTick(() => {
                plugin.isAudioMuted()
            })
        },
        addtoAudioList(audio) {
            const isin = this.audioList.some((item, index)=>{
                if(item.mid == audio.mid) {
                    return true
                }
            })
            if(isin) return 
            
            this.audioList.push(audio)           
        },
        async createContact(msg) {
            const call_id = msg.result.call_id || msg.call_id;
            const pattern = /:(.*?)(?=@)/; // cut sip and ip
            
            const cnumber = this.extractCompanyNumber(msg.result.displayname) || '';
            const name = msg.result.displayname || '';
            const cname = this.extractCompanyName(msg.result.displayname) || '';
            const number = msg.result.username.match(pattern)[1]

            //  const name = '+77070940064'
            //  const cname = 'Alga'
            //  const cnumber = '77070940064'
            // const number = '+380679408023'

        
            let ret = {
                id: call_id,
                name,
                number,
                transferring: false,
                route: false,
                company: '',
                uuid: null,
            };
        
            if (!cnumber && !cname) {
                return ret;
            } else {
                try {
                    const company = await this.getCompany({cnumber,cname});
                    ret.company = company ? { ...company, number: cnumber } : '';
                    return ret;
                } catch (error) {
                    if (error.message) this.$toast.error(error.message);
                    console.error(error);
                    return ret;
                }
            }
        },
             

        getContact(msg) { 
            return this.getContactFromList(msg)
        },
        getContactFromList(msg) {
            let temp = ''
            this.telProp.callList.some(e=>{
                if(e.contact.id == msg.call_id) {
                    temp = e.contact
                    return true
                }
            })
            return temp
        },

        getIdFromListPlugin(item) {
            let ret = -1
            this.telProp.callList.some((listItem, index)=>{
                if( listItem.plugin && listItem.plugin.id == item.plugin.id) {
                    ret = index
                    return true
                }
            })
            return ret
            
        },

        //входящий вызов
        vjanusIncomingCall(plugin,contact, jsep) {

            const newContact = {contact, jsep, plugin}

            if(this.plugin.getId == plugin.getId) {
                this.isMainPluginBizzy = true
            }
            const isrefresh = this.telProp.callList.some((item, index)=>{
                if(item.timer && item.contact.number == contact.number){
                    clearTimeout(item.timer)
                    item.timer = null
                    item.plugin = plugin
                    item.contact.id = contact.id
                    return true
                }
            })

            this.addHelper()
            
            if(isrefresh) return
            this.telProp.callList.push(newContact)
            if(!this.telProp.currentCall) {
                this.makeNoice()
                this.startBlinkingTitle()
            }
        },
        async getCompany(cinfo) {
            if (!cinfo.cnumber && !cinfo.cname) return null; // Вернем null вместо false
            try {
                const { cnumber, cname } = cinfo;
                const res = await api.chat.telephonyTreatmentIncomingNumber({ company_phone: cnumber, company_name: cname });
                return res.response;
            } catch (error) {
                if (error.message) this.$toast.error(error.message);
                console.error(error);
                return null; // Вернем null вместо false
            }
        },
        extractCompanyNumber(string) {
                if(!string) return null
                const phoneNumberRegex = /\d+/g;
                const matches = string.match(phoneNumberRegex);
            
                if (matches) {
                    // Объединяем все найденные цифры в одну строку
                    return matches.join('');
                } else {
                    return null; // Если цифры не найдены
                }
            
        }, 
        extractCompanyName(string) {
            if(!string) return ''
            const regex = /"(.*?)"/g;
            return string.match(regex);
        },
        getNumberFromUsername(inputString) {
            const startIndex = inputString.indexOf(':');
            const endIndex = inputString.indexOf('@');
          
            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
              const resultSubstring = inputString.substring(startIndex + 1, endIndex);
              return resultSubstring;
            } else {
              // Возвращаем пустую строку или что-то еще по вашему выбору в случае отсутствия ":" или "@",
              // или если ":" следует за "@"
              return '';
            }
        },
       
        getItemIndexFromCallList(item) {
            if(!item) {
                console.error('empty item',{item});
                return -1
            }
            const item_ud = item.id.split('@')[0]
            return this.telProp.callList.findIndex(e=>{
                const id = e.contact.id.split('@')[0]
                return id == item_ud
            })
        },
        async vjanusAccepted(plugin, msg) {
            this.telProp.isCall = true;
            this.telProp.calling = false;
        
            let contact = this.getContact(msg);
        
            if (!contact) {
                contact = await this.createContact(msg);
            }
        
            this.telProp.currentCall = { plugin, contact };
            this.telProp.telInput.phoneNumber = '';
        
            // remove accepted call from callList
            const index = this.getItemIndexFromCallList(this.telProp.currentCall.contact);
            if (index > -1) this.telProp.callList.splice(index, 1);
            this.callTimer = new Timer({affterUpdate: this.timerUpdate})
            this.callTimer.start()
        },

        hangupTelInputCall() {
            this.telProp.currentCall = null
            this.telProp.isCall = false,
            this.telProp.calling = false
            this.telProp.telInput.plugin = null
            this.telProp.telInput.dump_id = null
            this.clearTimer()
        },

        clearTimer() {
            if(this.callTimer) {
                this.callTimer.stop()
                this.callTimer = null
            }
        },

        vJanusDTMF(item) {
            const {plugin , phoneNumber} = item.telInput
            if(!plugin) return
            plugin.dtmf({
                dtmf: {tones: phoneNumber},
                error: (error) => {
                    console.error('Ошибка dtmf:', error);
                }
            });
        },

        makeNoice() {
            if('makeNoice', this.isNoising) return
            const sound = new Audio('/sound/ring.mp3');
            sound.volume = 0.13;
            this.isNoising = true;
            sound.play();
            sound.addEventListener('ended', function() {
                this.isNoising = false;
            });
        },
        startBlinkingTitle() {
            const favicon = document.querySelector('link[rel="icon"]')
            if(!favicon.dataset.dafaultFavicon) favicon.dataset.dafaultFavicon = favicon.href
            if(this.interval) return
            this.interval = setInterval(() => {
                const counter = this.telProp.callList.length
                const digitFavicon = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Ccircle cx='50' cy='50' r='40' fill='%23FF7F7F'/%3E%3Ctext x='50' y='58' font-weight='800' font-family='Arial, sans-serif' font-size='46' fill='white' text-anchor='middle'%3E"+counter+"%3C/text%3E%3C/svg%3E"
                if (document.title === this.$route.meta.title) {
                  document.title = 'Входящий вызов: ' + counter;
                  favicon.href = digitFavicon
                } else {
                  document.title = this.$route.meta.title;
                  favicon.href = favicon.dataset.dafaultFavicon
                }

                if(!counter){
                    this.stopBlinkingTitle()
                }
            }, 1000);
        },
        stopBlinkingTitle() {
            const favicon = document.querySelector('link[rel="icon"]')
            clearInterval(this.interval);
            this.interval = null;
            document.title = this.$route.meta.title;
            favicon.href = favicon.dataset.dafaultFavicon
        }
    }
}