import req from './request'

const DEFAULT_ENDPOINTS = {
    activeDriver: '/dashboard/active-driver',
    commission: '/dashboard/commission',
    diffCcommission: '/dashboard/diff-commission',
    driving: '/dashboard/driving',
    ordersDriver: '/dashboard/orders-driver',
    parks: '/dashboard/parks',
    payments: '/dashboard/payments',
    registration: '/dashboard/registration',
    replenishment: '/dashboard/replenishment',
}

export default {
    activeDriver(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.activeDriver, param)
    },
    commission(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.commission, param)
    },
    diffCcommission(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.diffCcommission, param)
    },
    driving(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driving, param)
    },
    ordersDriver(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.ordersDriver, param)
    },
    parks(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.parks, param)
    },
    payments(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payments, param)
    },
    registration(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.registration, param)
    },
    replenishment(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.replenishment, param)
    }
}

