import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            tab_socket: null,
            status: null,
            errcounter: 0,
            minCountError: 5,
            maxCountError: 15,
            showsuccess: false,
            isErors: true
        }
    },
    methods: {
        tab_initSocket() {
            const _this = this
            this.tab_openSocket();
            this.tab_socket.onopen = function(e) {
                if(!_this.isErors) return
                //_this.$toast.success('Сокет успешно открыт')
                _this.isErors = false
            };
            
            this.tab_socket.onmessage = function(event) {
                if(event.data ==  'Authenticated') return 
                let data
                try {
                    data = JSON.parse(event.data)
                } catch(e) {
                    //_this.$toast.error(e.message);
                    //_this.$toast.error(event.data);
                    //console.error({e,event});
                    return
                }
                
                const { action } = data
                switch (action) {
                    case 'updateTable':
                        eventBus.$emit('updateTable', data);
                    break;
                    case 'ready_report':
                        eventBus.$emit('readyReport', data);
                    break;
                    case 'error_report_processing':
                        eventBus.$emit('errorReport', data);
                    break;
                    case 'create_call':
                        eventBus.$emit('createCall', data);
                    break;
                    case 'update_call':
                        data.result_action == "action_end_call" ?  eventBus.$emit('endCall', data) : ''
                    break;
                    default:
                    break;
                }
            };
            this.tab_socket.onclose = function(e) {
                const message = 'socket tables: Сокет закрыт, пытаемся открыть снова...'
                // if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                // }
                //_this.$toast.error(message, {e})
                let time = 1000 // Пауза в 1 секунду перед повторной попыткой открыть сокет
                if(!(_this.errcounter % _this.minCountError)) { 
                    // time  = 120000
                    time = 10000
                }
                setTimeout(() => {
                    _this.tab_initSocket()
                }, time);
                
            }
            this.tab_socket.onerror = function(e) {
                const message = 'socket tables: Ошибка при открытии сокет, пытаемся открыть снова...'
                if(_this.errcounter < _this.maxCountError && (!_this.errcounter  ||  !(_this.errcounter  % _this.minCountError ))) {
                    //_this.$toast.error(message)
                }
                if  (_this.errcounter == _this.maxCountError) {
                    this.showsuccess = true
                    //_this.$toast.error('Мы уведомим вас когда сокет будет работать')
                }
                _this.errcounter  += 1
                //console.error(message);
            }
          
            eventBus.$on('socketEmit_tab', this.tab_socketEmit);
        },
        // tab_settimer() {
        //     setTimeout(() => {
        //         this.tab_socket.close()
        //         this.tab_initSocket();
        //     }, 30000); 
        // },
        tab_openSocket() {
            const token = this.user.token
            this.tab_socket = null
            this.tab_socket = new WebSocket('wss://new-8002.fleetsoft.pro?token='+token);
            if(this.errcounter > this.maxCountError && this.showsuccess) {
                this.errcounter  = 0
                this.showsuccess = false
                //this.$toast.success('Сокет сново открыт')
            }
            if(this.tab_socket.readyState == WebSocket.OPEN) {
            }
            // this.tab_settimer()
        },
        tab_socketEmit(data) {
            const body = JSON.stringify(data.data)
            if (this.tab_socket.readyState === WebSocket.OPEN) {
                this.tab_socket[data.method](body)
            } else {
                //this.$toast.error('WebSocket is already in CLOSING or CLOSED state.', {data});
                //console.error('socket tables: WebSocket is already in CLOSING or CLOSED state.', {data})
            }
        }
    }
}