import req from './request'

const DEFAULT_ENDPOINTS = {
    upload: '/upload', // GET {{api_url}}/financial/task-pool - для вкладки (основной) "Задачи"
}

export default {
    upload(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.upload, param)
    },
}