<template>
    <div class="tel-wind__input">
        <v-custom-input class="size_medium width__full"
            placeholder="введите номер"
            v-model="value.phoneNumber"
            :disabled="disabled"
            @input="handlInput"
            @keyup="handlKeyUp"
        />
        <button class="tel-wind__call button button_with_icon button_type_icon button_color_transp-black" 
            type="submit"
            :style='{opacity: disabled || isCall ? .5 : "" }'
            v-if="!disabled"
                @click="$emit('action',{action:'call', phoneNumber: value.phoneNumber})"
                :disabled="disabled || isCall"
                
            >
                <svg aria-hidden="true" width="30" height="30">   
                    <use href="#icon-phone-circle"></use> 
                </svg>
        </button>
        <button class="tel-wind__call button button_with_icon button_type_icon button_color_transp-black tel-wind__call-active" 
            type="submit"
            v-else
                @click="$emit('action',{action:'inchangup', item:{plugin:value.plugin}})"
            >
                <svg aria-hidden="true" width="30" height="30">   
                    <use href="#icon-phone-denay-circle"></use> 
                </svg>
        </button>
    </div>
</template>

<script>
    import vCustomInput from '@components/global/CustomInput'
    export default {
        props: {
            value: {
                type: Object,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isCall: {
                type: Boolean,
                default: false
            }
        },
        components: {
            vCustomInput
        },
        methods: {
            handlKeyUp() {
                this.value.phoneNumber = this.value.phoneNumber.replace(/[^+\d]|(?!^)\+/g, '')
            },
            handlInput() {
                this.$emit('input', this.value)
            },
        }
    }
</script>

<style lang="scss">

</style>