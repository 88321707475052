import api from '@api'
export default {
    state: { 
        application_instance_list: null,
        candidate_type_driver: null,
        lead_status: null,
        licence_country_list: null,
        mask_phone: null,
        park_list: null,
        work_status: null,
        profile_list: null,
        ie_ogds_list: null
    },
    mutations: {
        driver_refrences_success(state,data) {
            const { 
                application_instance_list, 
                candidate_type_driver,
                lead_status,
                licence_country_list,
                mask_phone,
                park_list,
                work_status,
                profile_list,
                ie_ogds_list
            } = data
            state.application_instance_list = application_instance_list
            state.candidate_type_driver = candidate_type_driver
            state.lead_status = lead_status
            state.licence_country_list = licence_country_list
            state.profile_list = profile_list
            state.ie_ogds_list = ie_ogds_list
            mask_phone.prefix = mask_phone.prefix.replaceAll(/\d/g, '9')
            state.mask_phone = mask_phone
            
            state.work_status = work_status
            state.park_list = park_list
        },
        RESET(state) {
            state.application_instance_list = null
            state.candidate_type_driver = null
            state.lead_status = null
            state.licence_country_list = null
            state.mask_phone = null
            state.park_list = null
            state.work_status = null
            state.profile_list = null
        }
    },
    actions: {
        GET_DRIVER_REFRENCES({commit}){
            return new Promise( (resolve, reject) => {
                api.driverReferences.get().then(res => {
                    commit('driver_refrences_success', res)
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                    console.error(err)
                    // Тост не рабюотате
                    // this.$toast.error(err.message || err, {
                    //     timeout: 5000
                    // });
                })
            })
        },
        RESET_DRIVER_REFRENCES({commit, state}) {
            commit('RESET')
        }
    },
    getters: {
        application_instance_list: state => state.application_instance_list,
        candidate_type_driver : state => state.candidate_type_driver,
        lead_status: state => state.lead_status,
        licence_country_list: state => state.licence_country_list,
        mask_phone: state => state.mask_phone,
        park_list: state => state.park_list,
        work_status: state => state.work_status,
        profile_list: state => state.profile_list,
        ie_ogds_list: state => state.ie_ogds_list
    }
}