<template>
    <div class="header-notification">
        <button class="header-notification__button button button_size_small button_color_transp-black button_with_icon"
            title="Уведомления"
        >
            <div class="header_button_icon header-notification__icon">
                <span v-if="count" class="header-notification__button-counter">
                    <template v-if="count > 99">
                        99+
                    </template>
                    <template v-if="count<100">
                        {{ count }}
                    </template>
                </span>
                <svg aria-hidden="true" width="20" height="20">
                    <use href="#icon-bell"></use>
                </svg>
            </div>
            <!-- <span class="header-notification__text">
                Уведомления
            </span> -->
        </button>
    </div>
</template>

<script>
export default {
    props:{
        count:{
            type: Number,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import '../../../../../gulp-src/app/scss/_variables';
.header-notification {
    &__button {
        background-color: transparent;
        position: relative;
        &-counter {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            height: 20px;
            position: absolute;
            top: -12px;
            right: -9px;
            padding: 3px;
            font-size: 12px;
            background-color: $global-rage;
            border-radius: 14px;
            border: 2px solid $global-white;
            color: $global-white;
        }  
    }

    &__text {
        color:#0A0A17;
    }

    &__icon {
        position: relative;
    }
}
</style>