<template>
    <transition name="swipe-top" mode="out-in">
        <div class="topbar" :style="{top: hederHeight}" v-if="isShow">
            <div class="topbar__bg" :style="{right: topbarPaddingRight? (topbarPaddingRight-20)+'px':''}"></div>
            <div class="topbar__content" ref="cont" >
                <div class="topbar__pre_wrapper" :style="{paddingRight:marginWrapper}">
                    <div class="topbar__wrapper" ref="wind">
                        <v-quick-pay v-if="tb_data.name == 'pay'" :selected="tb_data.value" @hide="SET_TOPBAR({name:'pay', value:false})" /> <!--:selected="barSelected" -->
                        <v-findepart v-if="tb_data.name == 'fin'" :value="tb_data.value" @hide="SET_TOPBAR({name:'fin', value:false})" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
    import vQuickPay from './modules/QuickPay.vue';
    import vFindepart from './modules/Findepart.vue';
    export default {
        name: 'vTopbar',
        props: {
            hederHeight: {
                type: String,
                default: ''
            }
        },
        components: {
            vQuickPay,
            vFindepart
        },
        watch: {
            topbarPaddingRight(newValue, oldValue) {
              this.updatePadding(newValue)
            }
        },
        computed: {
            ...mapState({
                tb_data: state => state.topbar.tb_data,
                topbarPaddingRight: state => state.topbar.paddingRight,
            }),
            isShow() {
                const ret = !!this.tb_data.show
                if (ret) {
                    this.$nextTick(() => {
                        this.updatePadding(this.topbarPaddingRight)
                        this.watchHeight();
                    })
                }
                return ret
            }
        },
        data() {
          return {
              topbarPadding: null,
              marginWrapper: null,
          }  
        },
        mounted() {
            
            
        },
        methods: {
            ...mapActions([
                'SET_TOPBAR'
            ]),
            updatePadding(value) {
                const isShow = !!this.tb_data.show
                if(!isShow) return
                const $wind = this.$refs.wind
                if(!value || value < 0 ) {
                    $wind.style.marginRight = ''
                    return
                }
                $wind.style.marginRight = (value-30) + 'px'
            },
            watchHeight() {
                // Получаем ссылку на ваш элемент topbar__content
                const topbarContent = this.$refs.cont;
                const _this = this
                // Функция для отслеживания изменений высоты элемента
                const observeHeightChanges = () => {
                    let height = topbarContent.getBoundingClientRect().height;
                    height = height? height+20: 0
                    _this.$emit('heightChange', height);
                    
                };

                // Вызываем функцию при монтировании компонента
                observeHeightChanges();

                // Следим за изменениями размеров элемента с использованием ResizeObserver
                const resizeObserver = new ResizeObserver(observeHeightChanges);
                resizeObserver.observe(topbarContent);
            },
        }
    }
</script>

<style lang="scss">
    .topbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        padding-left: 51px;
        

        @media (min-width: 1500px) {
            padding-left: 83px;
        }
        &__bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            background-color: #fff;
            box-shadow: -4px 0px 12px 0px rgba(58, 65, 70, 0.06), -8px 0px 16px 0px rgba(58, 65, 70, 0.12);
            transition: right 0.3s ease 0s;
        }
        &__content {
            padding: 32px 0;            
        }
        &__wrapper {
            // width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            
            @media (min-width:2000px) {
                max-width: 1920px;
                margin: 0 auto;
            }
        }
        
    }
</style>