import req from './request'

const DEFAULT_ENDPOINTS = {
    noComplete: '/dumpsRegistered/marketing/no-complete',
    Complete: '/dumpsRegistered/marketing/complete',
    Statistic: '/dumpsRegistered/marketing/statistic',
    calling: '/dumpsRegistered/calling',
    driver: '/dumpsRegistered/driver-info/general',
    promotion: '/dumpsRegistered/driver-info/promotions',
    toWork: '/dumpsRegistered/driver-info/to-work',
    comments: '/dumpsRegistered/driver-info/comments',
    addcomment: '/dumpsRegistered/driver-info/add-comment',
    assetCall: '/dumpsRegistered/asset-call',
    detail: '/dumpsRegistered/marketing/statistic/detail',
}

export default {
    noComplete(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.noComplete, param)
    },
    complete(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.Complete, param)
    },
    statistic(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.Statistic, param)
    },
    calling(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calling, param)
    },
    driver(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driver, param)
    },
    promotion(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promotion, param)
    },
    toWork(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.toWork, param)
    },
    comments(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.comments, param)
    },
    addcomment(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.addcomment, param)        
    },
    assetCall(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.assetCall, param)
    },
    detail(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.detail, param)
    },
    regcalling(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.regcalling, param)
    }
}