<template>
    <v-top-bar-slot @hide="$emit('hide',false)">
        <p class="title" slot="header">Заявка фин. отдел</p>
        <template slot="body">
            <div class="topbar-content__row">
                <div class="topbar-content__col" style="max-width: 70%;">
                    <v-custom-area
                        class="size_large"
                        placeholder="Введите текст"
                        label="Текст"
                        v-model="task_text"
                        :error="errors? errors.task_text: ''"
                        :disabled="isbizzy"
                    />
                </div>
                <div class="topbar-content__col">
                    <v-uploader ref="uploader"
                        v-model="file" label="файл" 
                        :error="errors? errors.task_text: ''"
                    />
                </div>
                <div class="topbar-content__col" style="padding-top: 26px;">
                    <v-button 
                        class="size_large button_color_black button_size_large-input" 
                        @click="handlSubmit"
                        :disabled="isbizzy

                    ">
                        отправить
                    </v-button>
                </div>
            </div>
        </template>
    </v-top-bar-slot>
</template>


<script>
import vTopBarSlot from './topBarSlot'
import vCustomArea from '@components/global/CustomArea'
import vUploader from '@components/global/Uploader'
import vButton from '@components/global/vButton'
import errors from '@mixin/errors'
import api from '@api'

export default {
    name: 'vfindepart',
    mixins: [errors],
    components: {
        vTopBarSlot,
        vCustomArea,
        vUploader,
        vButton
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            task_text: '',
            task_type: 2,
            file: null,
            isbizzy: false
        }
    },
    methods: {
        handlSubmit() {
            const driver_id = this.$route.query.driver_id
            const {file, task_text, task_type} = this
            const payload = {task_text, task_type, driver_id}
            this.isbizzy = true
            this.clearError()
            this.sendtext(payload).then(res=>{
                if(file) {
                    const id = res.response.id
                    this.isbizzy = true
                    this.sendFile(file, id).then(response=>{
                        console.log({sendFile:response});
                        this.task_text = ''
                        this.file = null
                        this.$refs.uploader.clear()
                        return res
                    }).finally(e=>{
                        this.isbizzy = false
                    })
                }
                if(!file) this.task_text = ''
                return res
            }).finally(e=>{
                this.isbizzy = false
            })
            
        },
        sendFile(file, id) {
            const formData = new FormData();
            formData.append('model', 'TaskPool');        // Добавляем поле 'model'
            formData.append('model_id', id);             // Добавляем идентификатор модели
            formData.append('file_part', '1');           // Добавляем часть файла (если нужно)
            formData.append('file', file);               // Добавляем сам файл
            
            return api.upload.upload(formData,'','post').then(res=>{
                if(res.response && res.response.message) this.$toast.success(res.response.message)
                return res
            }).catch(error=>{
                if(error.response) this.fillErrors(error.response)
                if(error.message) this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        sendtext(payload) {
            return api.financial.tp(payload,'','post').then(res=>{
                if(res.response && res.response.message) this.$toast.success(res.response.message)
                return res
            }).catch(error=>{
                if(error.response) this.fillErrors(error.response)
                if(error.message) this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        }
    }
}
</script>

<style lang="scss">
    .topbar__content {
        .custom-area__textarea textarea {
            max-height: 425px;
        }
    }
</style>