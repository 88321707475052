import antifraud from './modules/antifraud'
import auth from './auth/modules/auth'
import bannerImage from './modules/bannerImage'
import candidats from './modules/candidats'
import card from './modules/card'
import carReferences from './modules/carReferences'
import chart from './modules/chart'
import chat from './modules/chat'
import communications from './modules/communications'
import company from './modules/company'
import dashboard from './modules/dashboard'
import driverReferences from './modules/driverReferences'
import drivers from './modules/drivers'
import dumps from './modules/dumps'
import dumpsFinished from './modules/dumpsFinished'
import dumpsInWork from './modules/dumpsInWork'
import dumpsLeaddriver from './modules/dumpsLeaddriver'
import dumpsNew from './modules/dumpsNew'
import dumpsPhotocontrol from './modules/dumpsPhotocontrol'
import dumpsRegistered from './modules/dumpsRegistered'
import dumpsRepeatdriver from './modules/dumpsRepeatdriver'
import financial from './modules/financial'
import ie from './modules/ie'
import nac from './modules/nac'
import other from './modules/other'
import overdraft from './modules/overdraft'
import payments from './modules/payments'
import permission from './modules/permission'
import profile from './modules/profile'
import promotions from './modules/promotions'
import push from './modules/push'
import references from './modules/references'
import referral from './modules/referrals'
import rentauto from './modules/rentauto'
import selfemployment from './modules/selfemployment'
import sms from './modules/sms'
import teams from './modules/teams'
import tel from './modules/tel'
import terminals from './modules/terminals'
import transaction from './modules/transaction'
import transactions from './modules/transactions'
import translations from './modules/translations'
import upload from './modules/upload'
import vexport from './modules/export'

export default {
    antifraud,
    auth,
    bannerImage,
    candidats,
    card,
    carReferences,
    chart,
    chat,
    communications,
    company,
    dashboard,
    driverReferences,
    drivers,
    dumps,
    dumpsFinished,
    dumpsInWork,
    dumpsLeaddriver,
    dumpsNew,
    dumpsPhotocontrol,
    dumpsRegistered,
    dumpsRepeatdriver,
    financial,
    ie,
    nac,
    other,
    overdraft,
    payments,
    permission,
    profile,
    promotions,
    push,
    references,
    referral,
    rentauto,
    selfemployment,
    sms,
    teams,
    tel,
    terminals,
    transaction,
    transactions,
    translations,
    upload,
    vexport,
}