<template>
    <div class="chat-contact" @click="$emit('click', contact)">
        <div class="chat-contact__bg" :style="{opacity}"></div>
        <div class="chat-contact__img">
            <img :src="contact.image" :alt="contact.client_info">
            <div class="chat-contact__vip" v-if="contact.client_vip">
                <svg aria-hidden="true" width="18" height="18">
                    <use href="#icon-crown-2"></use>
                </svg>
            </div>
        </div>
        <div class="chat-contact__cont" >
            <div class="chat-contact__cont-top">
                <div class="chat-contact__text">
                    {{ contact.client_info }}
                </div>
                <div class="chat-contact__date">{{ contact.date_create }}</div>
            </div>
            
            <div class="chat-contact__cont-bottom">
                <div class="chat-contact__message">
                    {{ contact.message }}
                </div>

                <div class="chat-contact__counter" v-if="contact.count_unread">
                    <template v-if="contact.count_unread < 100">
                        {{ contact.count_unread }}
                    </template>
                    <template v-else>
                        99+
                    </template>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        contact:''
    },

    data () {
        return {
            timer:null,
            currentTime: null,
            opacity: null
        }
    },
    beforeDestroy() {
        clearInterval(this.time)
    },
    mounted() {
        
        this.timerStart()
    },
    methods: {
        timerStart() {
            this.time= setInterval(()=>{
                const currentTime  = new Date().getTime()
                const time =this.contact.time
                const sec = (currentTime - time) / 1000
                this.opacity = ( (sec / 120)*100 ) / 100
                this.opacity > 1 ? this.opacity = 1 : null
            }, 100);
        }
    }
}
</script>

<style lang="scss">
    .chat-contact {
        display: flex;
        padding-bottom: 12px;
        padding-top: 12px;
        border-top: 1px solid #CBCCCF;
        max-width: 100%;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        &__bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #FFEFEB;
            z-index: -1;
            opacity: 0;
        }
        &:last-child {
            border-bottom: 1px solid #CBCCCF;
        }

        &__img {
            flex: 0 0 48px;
            margin-right: 12px;
            display: flex;
            position: relative;
            img {
                border-radius: 50%;
                width: 48px;
                height: 48px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        &__cont {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100%;
            overflow: hidden;
        }
        &__cont-top,&__cont-bottom { 
            display: flex;
            width: 100%;
            align-items: center;
            
        }

        &__cont-bottom { 
            margin-top: 5px;
        }

        &__text { 
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #0A0A17;
            margin-bottom: auto;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
        }
        &__message {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #0A0A17;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 15px;
            span {
                color: #919197;
            }
        }
        &__tools {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
        }
        &__date {
            margin-left: auto;
            white-space: nowrap;
        }
        &__counter { 
            display: flex;
            flex: 0 0 24px;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: #3A4146;
            border-radius: 50%;
            color: #fff;
            margin-top: auto;
            margin-left: auto;
            border: 3px solid #3A4146;
            overflow: hidden;
        }

        &__vip {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            height: 28px;
            background-color: #3A4146;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            svg {
                width: 18px;
                height: 18px;
                fill: #FF9C11;
            }

        }
    }
</style>